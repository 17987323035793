import { Form, useSearchParams } from '@remix-run/react';
import { ActionFunctionArgs, LinksFunction, LoaderFunctionArgs, MetaFunction, redirect } from '@remix-run/node';
import styles from '~/styles/pages/login.css?url';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '~/components/ui/card';
import { Alert, AlertDescription } from '~/components/ui/alert';
import { Label } from '~/components/ui/label';
import { Input } from '~/components/ui/input';
import { Button } from '~/components/ui/button';
import { Krovy } from '~/lib/krovy/Krovy';
import { AuthService } from '~/services/AuthService';

export const meta: MetaFunction = () => {
  return [
    { title: 'Login - Krovy dashboard' },
    { name: 'description', content: 'Login in krovy dashboard!' },
  ];
};

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles }
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await AuthService.getSession(request);
  if (session) {
    return redirect('/');
  }
  return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const username = String(formData.get('username'));
  const password = String(formData.get('password'));
  // TODO validate!!
  const loginResponse = await Krovy.authentication.login(username, password);
  const response = redirect('/');
  return AuthService.setCookieOnResponse(response, loginResponse);
}

export default function Login() {
  const [searchParams] = useSearchParams();
  const hasLoginError = searchParams.get('error');
  return <div className="flex h-screen text-white">
    <div className="flex items-center justify-center w-full">
      <Card>
        <CardHeader>
          <CardTitle>Welcome to Krovy</CardTitle>
          <CardDescription>Log in to access your dashboard</CardDescription>
        </CardHeader>
        <Form method="post" className="w-full">
          <CardContent>
            <Label htmlFor="username">Username</Label>
            <Input id="username" name="username" type="text" placeholder="Username" required/>
            <Label htmlFor="password">Username</Label>
            <Input id="password" name="password" type="password" placeholder="Password" required/>
            {/*<Button type="submit" variant="outline" className="w-full">*/}
            {/*  <FcGoogle className="mr-2 h-4 w-4"/>*/}
            {/*  Sign in with Google*/}
            {/*</Button>*/}
            {hasLoginError && <Alert variant="destructive" className="mt-4">
              <AlertDescription>There was an error trying to log in</AlertDescription>
            </Alert>}
          </CardContent>
          <CardFooter>
            <Button type="submit" className="w-full">Log in</Button>
          </CardFooter>
        </Form>
      </Card>
    </div>
  </div>
}
